import { useEffect, useState, useMemo } from "react";
import { isNotEmpty, toAbsoluteUrl } from "../../../../_metronic/helpers";
import clsx from "clsx";
import axios from "axios";
import { toast } from "react-toastify";
import { VehicleSwapModal } from "../vehicleSwapModal/VehicleSwapModal";


const VehicleEditModalForm = ({
  vehicles,
  setSelectedBookingId,
  setVehicles,
  fetchBooking,
  selectedBookingId,
  fetchBookingId,
  bookingDetails,
}) => {
  const [bookingVehicles, setBookingVehicles] = useState([]);
  const [userVehiclesList, setUserVehiclesList] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [customerDetails, setCustomerDetails] = useState(null);

  const getUserDetails = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/user/${bookingDetails?.customer_uid}`).then((res) => {
      const temp = res.data.vehicles.map((_v) => {
        if (vehicles.find(item => item.vehicle_id === _v?.id)) {
          return {
            ..._v,
            selected: true
          }
        } else {
          return {
            ..._v,
            selected: false
          }
        }
      });
      setUserVehiclesList(temp?.filter((item) => !item.selected));
      setCustomerDetails(res.data);
    })
  }

  useEffect(() => {
    getUserDetails();
    getBookingVehicles();
  }, [selectedVehicle])

  useEffect(() => {
    setVehicles(bookingDetails?.vehicles);
  }, [bookingDetails?.vehicles])


  const getBookingVehicles = () => {
    axios.get(`${process.env.REACT_APP_API_V2_URL}/booking/${selectedBookingId}/vehicles`).then((res) => {
      setBookingVehicles(res.data);
    })
  }



  return (
    <>
      {/* begin::Scroll */}
      <div
        className="d-flex flex-column me-n7 pe-7"
        style={{ height: 'auto', maxHeight: '380px' }}
        id="kt_modal_add_user_scroll"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-max-height="auto"
        data-kt-scroll-dependencies="#kt_modal_add_user_header"
        data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
        data-kt-scroll-offset="300px"
      >
        <div className="mb-5">
          {vehicles?.length > 0 && vehicles?.map((item) => (
            <div
              className="vehicle-card"
              style={{
                borderRadius: "10px",
                border: "1px solid #b2b2b2",
                padding: "10px",
                background: "#f5f5f5",
                marginBottom: "10px",
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
                position: "relative",
                marginBottom: "15px"
              }}
            >
              <span
                style={{
                  position: "absolute",
                  background: "#ed2626",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  right: "-10px",
                  top: "-10px",
                  cursor: "pointer"
                }}
                onClick={() => {
                  setSelectedVehicle(item);
                }}
              >
                <i
                  className="fas fa-exchange-alt"
                  style={{ color: "#fff" }}
                />
              </span>
              <div className="vehicle-label">
                <h6 style={{ color: "#a3a3a3" }}>
                  VEHICLE ID
                </h6>
                <h5 style={{ color: "#4a4a4a" }}>
                  {item?.vehicle_id || "-"}
                </h5>
              </div>
              <div className="vehicle-label">
                <h6 style={{ color: "#a3a3a3" }}>
                  VEHICLE TYPE
                </h6>
                <h5 style={{ color: "#4a4a4a" }}>
                  {+item?.vehicle_type === 1
                    ? "TRUCK"
                    : +item?.vehicle_type === 2
                      ? "TRAILER"
                      : "TRUCK + TRAILER"
                  }
                </h5>
              </div>
              {+item.vehicle_type !== 2 && (
                <div className="vehicle-label">
                  <h6 style={{ color: "#a3a3a3" }}>
                    COMPANY NAME
                  </h6>
                  <h5 style={{ color: "#4a4a4a" }}>
                    {item?.name || "-"}
                  </h5>
                </div>
              )}
              {+item.vehicle_type !== 2 && (
                <div className="vehicle-label">
                  <h6 style={{ color: "#a3a3a3" }}>
                    TRUCK #
                  </h6>
                  <h5 style={{ color: "#4a4a4a" }}>
                    {item?.truck_number || "-"}
                  </h5>
                </div>
              )}
              {+item.vehicle_type !== 2 && (
                <div className="vehicle-label">
                  <h6 style={{ color: "#a3a3a3" }}>
                    TRUCK MAKE
                  </h6>
                  <h5 style={{ color: "#4a4a4a" }}>
                    {item?.make || "-"}
                  </h5>
                </div>
              )}
              {+item.vehicle_type !== 2 && (
                <div className="vehicle-label">
                  <h6
                    style={{
                      color: "#a3a3a3",
                      textTransform: "uppercase"
                    }}
                  >
                    TRUCK PLATE #
                  </h6>
                  <h5 style={{ color: "#4a4a4a" }}>
                    {item?.plate_number || "-"}
                  </h5>
                </div>
              )}
              {+item.vehicle_type !== 1 && (
                <div className="vehicle-label">
                  <h6 style={{ color: "#a3a3a3" }}>
                    TRAILER TYPE
                  </h6>
                  <h5 style={{ color: "#4a4a4a" }}>
                    {item.vehicle_type !== 1
                      ? item?.model || "-"
                      : "Not Available"
                    }
                  </h5>
                </div>
              )}
              {+item.vehicle_type !== 1 && (
                <div className="vehicle-label">
                  <h6 style={{ color: "#a3a3a3" }}>
                    TRAILER #
                  </h6>
                  <h5
                    style={{
                      color: "#4a4a4a",
                      textTransform: "uppercase"
                    }}
                  >
                    {item?.trailer_number || "-"}
                  </h5>
                </div>
              )}
              {+item.vehicle_type !== 1 && (
                <div className="vehicle-label">
                  <h6 style={{ color: "#a3a3a3" }}>
                    TRAILER PLATE #
                  </h6>
                  <h5
                    style={{
                      color: "#4a4a4a",
                      textTransform: "uppercase"
                    }}
                  >
                    {item?.trailer_plate || "-"}
                  </h5>
                </div>
              )}
              {+item.vehicle_type == 2 && (
                <div className="vehicle-label">
                  <h6 style={{ color: "#a3a3a3" }}>
                    COMPANY NAME
                  </h6>
                  <h5
                    style={{
                      color: "#4a4a4a",
                      textTransform: "uppercase"
                    }}
                  >
                    {item?.name || "-"}
                  </h5>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {selectedVehicle && <VehicleSwapModal
        vehicles={userVehiclesList}
        setVehicles={setVehicles}
        selectedVehicle={selectedVehicle}
        setSelectedVehicle={setSelectedVehicle}
        selectedBookingId={selectedBookingId}
        fetchBooking={fetchBooking}
        fetchBookingId={fetchBookingId}
        bookingDetails={bookingDetails}
        customerDetails={customerDetails}
        getUserDetails={() => {
          getUserDetails();
          fetchBookingId(selectedBookingId);
          setSelectedVehicle(null);
        }}
      />
      }
    </>
  );
};

export { VehicleEditModalForm };
