import { useEffect, useState } from 'react'
import { initialQueryState, KTIcon, useDebounce } from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import "./style.css"

const UsersListSearchComponent = () => {
  const { updateState } = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState('')
  const [searchBy, setSearchBy] = useState('1')
  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined && searchBy) {
        if (searchBy == 1) {
          updateState({ customer_name: debouncedSearchTerm, ...initialQueryState })
        } else if (searchBy == 2) {
          updateState({ customer_public_id: debouncedSearchTerm, ...initialQueryState })
        }
        else if (searchBy == 3) {
          updateState({ reservation_code: debouncedSearchTerm, ...initialQueryState })
        }
        else if (searchBy == 4) {
          updateState({ listing_name: debouncedSearchTerm, ...initialQueryState })
        }
      }

      else if (searchBy === '') {
        setSearchTerm('')
        updateState({ customer_name: '', customer_public_id: '', reservation_code: '', listing_name: '', ...initialQueryState })
      }
    },
    [debouncedSearchTerm, searchBy]
  )


  useEffect(() => {
    setSearchTerm('')
    updateState({ customer_name: '', customer_public_id: '', reservation_code: '', listing_name: '', ...initialQueryState })
  }, [searchBy])


  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='mx-5 dropdown-search'>
        <select
          className="form-select form-select-solid fw-bolder"
          data-kt-select2="true"
          data-placeholder="Select option"
          data-allow-clear="true"
          data-kt-user-table-filter="two-step"
          data-hide-search="true"
          value={searchBy}
          onChange={(e) => setSearchBy(e.target.value)}
        >
          <option value="1">
            Name
          </option>
          <option value="2">
            Member Number
          </option>
          <option value="3">
            Reservation Number
          </option>
          <option value="4">
            Listing Name
          </option>
        </select>
      </div>

      <div className='d-flex align-items-center position-relative my-1 search-container'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type={'text'}
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-300px ps-14 user-search-input'
          placeholder={`Search by ${searchBy == 2 ? 'Member Number' : searchBy == 3 ? 'Reservation Number' : searchBy == 1 ? 'Name' : 'Listing Name'}`}
          value={searchTerm}

          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
    </div>
  )
}

export { UsersListSearchComponent }
