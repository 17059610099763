import { useState, useEffect } from "react";
import { KTCard, KTCardBody, KTIcon } from "../../../_metronic/helpers";
import axios from "axios";
import "./style.css";
import { AddNewReportModal } from "./ReportModal/AddNewReportModal";
import moment from "moment";
import { ScheduleReportsHeader } from "./header/ScheduleReportsHeader";
import { ListingModal } from "./ListingModal/ListingModal"
import { NotifierEmailModal } from "./NotifiersEmail/NotifierEmailModal";
import { Link } from "react-router-dom";
import { daysOfWeek, ToastStyle } from "../../../_metronic/helpers/utils";
import { toast } from "react-toastify";
import { useAuth } from "../auth";


const ScheduleReports = () => {
  const { currentUser, haveAccess } = useAuth();
  const [reports, setReports] = useState([])
  const [selectedReport, setSelectedReport] = useState(null);
  const [openNewReportModal, setOpenNewReportModal] = useState(false);
  const [openListingModal, setOpenListingModal] = useState(false);
  const [openNotifierEmailModal, setOpenNotifierEmailModal] = useState({
    status: false,
    type: ''
  });
  const [loading, setLoading] = useState(false)

  const getScheduleReports = () => {
    axios.get(`${process.env.REACT_APP_API_V2_URL}/reports/excel`)
      .then((res) => {
        if (res.data) {
          const data = res.data.map((item) => {
            return {
              ...item,
              member_emails: item.member_emails ? item.member_emails?.split(",") : [],
              emails: item.emails ? item?.emails?.split(",") : [],
              sent_to: item.sent_to ? item.sent_to.split(",") : [],
              listings: Object?.keys(item?.listings)?.map(id => ({
                id: +id,
                name: item.listings[id]
              }))
            }
          })
          setReports(data)
        }
      }).catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      });
  }

  useEffect(() => {
    getScheduleReports()
  }, [])

  const generateReport = (id) => {
    setLoading(true)
    axios.post(`${process.env.REACT_APP_API_V2_URL}/reports/excel/${id}/generate`).then((res) => {
      toast("Report Generated Successfully", ToastStyle);
      getScheduleReports()
      setLoading(false)
    }).catch((err) => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast(err.response.data.message, ToastStyle);
      } else {
        toast("Technical Error", ToastStyle);
      }
      setLoading(false)
    });
  }

  return (
    (haveAccess(currentUser, 'reports_list')) ?
      <KTCard>
        <ScheduleReportsHeader setOpenNewReportModal={setOpenNewReportModal} currentUser={currentUser} haveAccess={haveAccess} />
        <KTCardBody>
          <div className="row g-4">
            {reports && reports.length > 0 ? (
              <div
                className="mb-10 mt-5 rest-stop-container"
              >
                {reports?.sort((item1, item2) => moment(item2.createdAt)?.utc() - moment(item1.createdAt)?.utc())?.map((item, index) => (
                  <div
                    className="form-check form-check-custom form-check-solid d-flex justify-content-between align-items-center flex-wrap"
                    key={item.job_id}
                    style={{
                      background: item.is_active ? "#e3feee9e" : "#f9f9f9",
                      padding: "10px",
                      borderRadius: "5px"
                    }}
                  >
                    <div className="fv-row w-50">
                      <label className="fw-bold fs-6 mb-2">Created By</label>
                      <h5 className="text-main fw-bolder">{item.created_by_admin}</h5>
                    </div>
                    <div className="fv-row w-50" style={{ textAlign: "right" }}>
                      <label className="fw-bold fs-6 mb-2">Status</label>
                      <h5 className={`${item.is_active ? "text-success" : "text-danger"} fw-bolder`}>
                        {item.is_active ? "Active" : "Inactive"}
                      </h5>
                    </div>
                    <div className="fv-row w-50">
                      <label className="fw-bold fs-6 mb-2">{item.cycle === 1 ? "Day of the Week" : "Date of the Month"}</label>
                      <h5 className="text-main fw-bolder">
                        {item.cycle === 1 ? daysOfWeek?.find(day => day.id === item.day)?.name || "Sunday" : item.day}
                      </h5>
                    </div>
                    <div className="fv-row w-50" style={{ textAlign: "right" }}>
                      <label className="fw-bold fs-6 mb-2">Cycle</label>
                      <h5 className="text-main fw-bolder">
                        {item.cycle === 1 ? "Weekly" : "Monthly"}
                      </h5>
                    </div>
                    <div className="fv-row w-50">
                      <label className="fw-bold fs-6 mb-2">Created At</label>
                      <h5 className="text-main fw-bolder">{moment(item?.createdAt).utc().format("YYYY-MM-DD")}</h5>
                    </div>
                    <div className="fv-row w-50" style={{ textAlign: "right" }}>
                      <label className="fw-bold fs-6 mb-2">Updated At</label>
                      <h5 className="text-main fw-bolder">
                        {moment(item?.updatedAt).utc().format("YYYY-MM-DD")}
                      </h5>
                    </div>
                    <div className="fv-row w-100">
                      <label className="fw-bold fs-6 mb-2">Listing Name</label>
                      {
                        item?.listings && item.listings?.length ? (
                          item.listings.length < 2 ? (
                            <h5
                              className="text-main fw-bolder no-wrap break-word"
                            >
                              <Link
                                key={index}
                                to={`/listings/${item?.listings[0]?.id}`}
                                className="text-main fw-bolder"
                              >
                                {item?.listings[0]?.name || "-"}
                              </Link>
                            </h5>
                          ) : (
                            <h5>
                              <button
                                className="border-0 text-white"
                                style={{
                                  backgroundColor: "rgb(255, 164, 89)",
                                  borderRadius: "2px",
                                  padding: "4px",
                                }}
                                onClick={() => {
                                  setSelectedReport(item);
                                  setOpenListingModal(true);
                                }}
                              >
                                Show All Listings
                              </button>
                            </h5>
                          )
                        ) : (
                          <h5 className="text-danger">-</h5>
                        )
                      }
                    </div>
                    <div className="fv-row w-100">
                      <label className="fw-bold fs-6 mb-2">Notifier Emails</label>
                      {
                        item?.emails && item.emails?.length ? (
                          item.emails.length < 2 ? (
                            <h5
                              className="text-main fw-bolder no-wrap break-word"
                            >
                              <span key={index}>{item?.emails[0] || "-"}</span>
                            </h5>
                          ) : (
                            <h5>
                              <button
                                className="border-0 text-white"
                                style={{
                                  backgroundColor: "rgb(255, 164, 89)",
                                  borderRadius: "2px",
                                  padding: "4px",
                                }}
                                onClick={() => {
                                  setSelectedReport(item);
                                  setOpenNotifierEmailModal({
                                    status: true,
                                    type: 'notifier'
                                  });
                                }}
                              >
                                Show All Notifier Emails
                              </button>
                            </h5>
                          )
                        ) : (
                          <h5 className="text-danger">-</h5>
                        )
                      }
                    </div>
                    <div className="fv-row w-100">
                      <label className="fw-bold fs-6 mb-2">Admin Emails</label>
                      {
                        item?.member_emails && item.member_emails?.length ? (
                          item?.member_emails.length < 2 ? (
                            <h5
                              className="text-main fw-bolder no-wrap break-word"
                            >
                              <span key={index}>{item?.member_emails[0] || "-"}</span>
                            </h5>
                          ) : (
                            <h5>
                              <button
                                className="border-0 text-white"
                                style={{
                                  backgroundColor: "rgb(255, 164, 89)",
                                  borderRadius: "2px",
                                  padding: "4px",
                                }}
                                onClick={() => {
                                  setSelectedReport(item);
                                  setOpenNotifierEmailModal({
                                    status: true,
                                    type: 'admin'
                                  });
                                }}
                              >
                                Show All Admin Emails
                              </button>
                            </h5>
                          )
                        ) : (
                          <h5 className="text-danger">-</h5>
                        )
                      }
                    </div>
                    <div className="fv-row w-100">
                      <label className="fw-bold fs-6 mb-2">Last Report Sent At</label>
                      <h5 className="text-main fw-bolder">{item.sent_date ? moment(item?.sent_date).utc().format("YYYY-MM-DD, h:mm A") : 'Not Sent Yet'}</h5>
                    </div>
                    <div className="fv-row w-100">
                      <label className="fw-bold fs-6 mb-2">Last Report Sent To</label>
                      <h5 className="text-main fw-bolder">
                        {
                          item?.sent_to && item.sent_to?.length ? (
                            item.sent_to.length < 2 ? (
                              <h5
                                className="text-main fw-bolder no-wrap break-word"
                              >
                                <span key={index}>{item?.sent_to[0] || "-"}</span>
                              </h5>
                            ) : (
                              <>
                                <h5
                                  className="text-main fw-bolder no-wrap break-word"
                                >
                                  <span key={index}>{item?.sent_to[0] || "-"}</span>
                                </h5>
                                <h5>
                                  <button
                                    className="border-0 text-white"
                                    style={{
                                      backgroundColor: "rgb(255, 164, 89)",
                                      borderRadius: "2px",
                                      padding: "4px",
                                    }}
                                    onClick={() => {
                                      setSelectedReport(item);
                                      setOpenNotifierEmailModal({
                                        status: true,
                                        type: 'sent'
                                      });
                                    }}
                                  >
                                    Show All Emails
                                  </button>
                                </h5>
                              </>
                            )
                          ) : (
                            <h5 className="text-danger">-</h5>
                          )
                        }
                      </h5>
                    </div>
                    <div className="fv-row w-70">
                    <button
                      onClick={() => generateReport(item.job_id)}
                      className="btn btn-dark p-2"
                      data-kt-users-modal-action="cancel"
                      disabled={loading || !item.is_active}
                    >
                      Generate Report Now
                    </button>
                    </div>
                    {(haveAccess(currentUser, 'reports_edit')) && <div className="fv-row w-30">
                      <button
                        className="listing-remove"
                        onClick={() => {
                          setSelectedReport(item);
                          setOpenNewReportModal(true);
                        }}
                      >
                        <KTIcon iconName="pencil" />
                      </button>
                    </div>
                    }
                  </div>
                ))}
              </div>
            ) : (
              <div className="d-flex text-center w-100 align-content-center justify-content-center">
                No Scheduler Found
              </div>
            )}
          </div>
        </KTCardBody>
        {openNewReportModal && (
          <AddNewReportModal
            openNewReportModal={openNewReportModal}
            setOpenNewReportModal={setOpenNewReportModal}
            getScheduleReports={getScheduleReports}
            selectedReport={selectedReport}
            setSelectedReport={setSelectedReport}
            daysOfWeek={daysOfWeek}
          />
        )}
        {openListingModal && (
          <ListingModal
            setOpenListingModal={setOpenListingModal}
            selectedReport={selectedReport}
            setSelectedReport={setSelectedReport}
          />
        )}
        {openNotifierEmailModal?.status && (
          <NotifierEmailModal
            openNotifierEmailModal={openNotifierEmailModal}
            setOpenNotifierEmailModal={setOpenNotifierEmailModal}
            selectedReport={selectedReport}
            setSelectedReport={setSelectedReport}
          />
        )}
      </KTCard>
      :
      <div className='d-flex text-center w-100 align-content-center justify-content-center' style={{ height: '70vh', alignItems: 'center', fontSize: '42px', color: '#ffa049', background: 'rgba(0,0,0,0.1)' }}>
        You do not have permission to view
      </div>
  );
};

export default ScheduleReports;
