import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { formatPrice } from "../../../../_metronic/helpers/utils";

const WalletModalForm = ({
  setOpenTopUpWalletModal,
  getUser,
  user
}) => {
  const [amount, setAmount] = useState('');

  const cancel = () => {
    setAmount('');
    setOpenTopUpWalletModal(false);
  };


  const topUp = () => {
    axios.post('https://apiv1.truckparkingclub.com/test/v1/points', {
      user_id: user.id,
      reward_label: 'test',
      points: +amount * 100
  })
      .then(() => {
          getUser();
          toast.success('Cash wallet successfully reloaded')
      })
      .catch(() => {
          toast.error('Could not top up cash wallet balance')
      })
  }

  return (
    <>
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          <h3 className="mb-4">Available Cash Balance: <span className="fw-bolder text-success">{formatPrice(user?.points)}</span></h3>
          <div className="d-flex gap-2 topup-container">
          <button type="button" className={`btn rounded ${amount == 100 ? 'btn-main text-white' : 'topup'}`}   onClick={() => setAmount(100)}>$100</button>
          <button type="button" className={`btn rounded ${amount == 200 ? 'btn-main text-white' : 'topup'}`}  onClick={() => setAmount(200)}>$200</button>
          <button type="button" className={`btn rounded ${amount == 500 ? 'btn-main text-white' : 'topup'}`}  onClick={() => setAmount(500)}>$500</button>
          <input
                type="text"
                className="form-control form-control-solid w-100 "
                placeholder="Enter Custom Amount"
                value={amount}
                onChange={(e) => {
                  const newValue = e.target.value
                  const regex = /^[1-9]\d*(\.)?(\d{1,2})?$/
                  const isValidValue = regex.test(newValue)

                  if (isValidValue || e.target.value === '') {
                      setAmount(newValue);
                  }
                }}
              />
          </div>
        </div>
        <div className="text-center pt-15">
          <button
            type="reset"
            onClick={() => cancel()}
            className="btn btn-light mx-3"
            data-kt-users-modal-action="cancel"
          >
            Discard
          </button>

          <button
            type="submit"
            className="btn"
            data-kt-users-modal-action="submit"
            style={{ background: "#ffa459", color: "#fff" }}
            disabled={!amount}
            onClick={topUp}
          >
            <span className="indicator-label">Top Up</span>
          </button>
        </div>
    </>
  );
};

export { WalletModalForm };
