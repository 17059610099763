import moment from "moment";
import { formatPrice } from "../../../../../_metronic/helpers/utils";

const headers = [
  { title: 'Date' },
  { title: 'Time' },
  { title: 'Transactions' },
  { title: 'Reservation Number' },
]

export const PointsTable = ({ points}) => {
  return (
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
            {headers.map(column => (
              <th
                key={column.title}
                className={column.className}
              >
                {column.title}
              </th>
            ))}
          </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
          {points.length > 0 ? (
            points.map((row, i) => (
              <tr key={i}>
                <td style={{ minWidth: '180px'}}>{(moment(row.created_at).utc().format('YYYY-MM-DD'))}</td>
                <td style={{ minWidth: '120px'}}>{(moment(row.created_at).utc().format('HH:mm a'))}</td>
                <td style={{ minWidth: '130px', color: row.type === 'CR' ? '#2ecc71' : '#ed2626'}}> {row.type === 'CR' ? `+ ${formatPrice(row.credited_balance)}` : `- ${formatPrice(row.balance_consumed)}`} {row.transaction_type === 'review' && '(Review)'}</td>
                <td style={{ minWidth: '180px'}}>{(row.type == 'CR' && row.transaction_type === 'review') ? row.remarks : row.type == 'DR' ? row.remarks : 'N/A'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>
                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                  No Wallet History yet for this user
                </div>
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
  );
};
