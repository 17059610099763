import { KTIcon } from "../../../../_metronic/helpers";
import { formatPrice } from "../../../../_metronic/helpers/utils";

const PointsModalHeader = ({
  user,
  setOpenPointsModal,
}) => {
  return (
    <div className="modal-header">
      {/* begin::Modal title */}
      <div>
      <h2 className="fw-bolder mb-2">{`Cash Wallet Statements for ${user?.first_name} ${user?.last_name}`}</h2>
      <h3>Total Wallet Balance: <span className="fw-bolder text-success">{formatPrice(user?.points)}</span></h3>
      </div>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className="btn btn-icon btn-sm btn-active-icon-primary"
        data-kt-users-modal-action="close"
        onClick={() => {
          setOpenPointsModal(false);
        }}
        style={{ cursor: "pointer" }}
      >
        <KTIcon iconName="cross" className="fs-1" />
      </div>
      {/* end::Close */}
    </div>
  );
};

export { PointsModalHeader };
