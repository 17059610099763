import {useEffect} from 'react'
import {WalletModalHeader} from './WalletModalHeader'
import {WalletModalFormWrapper} from './WalletModalFormWrapper'

export const WalletModal = ({ getUser, user, setOpenTopUpWalletModal}) => {

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className={`modal-dialog modal-dialog-centered mw-650px`}>
          <div className='modal-content'>
            <WalletModalHeader setOpenTopUpWalletModal={setOpenTopUpWalletModal} />
            <div className={`modal-body scroll-y mx-5`}>
              <WalletModalFormWrapper getUser={getUser} user={user} setOpenTopUpWalletModal={setOpenTopUpWalletModal} />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'/>
    </>
  )
}
